<template>
 <div>
<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g transform="translate(-290 -688)">
        <rect width="60" height="60" transform="translate(290 688)" :fill="background" />
        <g transform="translate(300.709 698.708)">
            <g transform="translate(0 0)">
                <path d="M28.937,38.583H9.646A9.646,9.646,0,0,1,0,28.938V9.646A9.646,9.646,0,0,1,9.645,0H28.937a9.646,9.646,0,0,1,9.646,9.646V28.937a9.646,9.646,0,0,1-9.646,9.646" transform="translate(0 0)" fill="#00b487" fill-rule="evenodd" />
                <path d="M18.086,10.851H28.937A5.426,5.426,0,0,0,29.492.028C29.307.017,29.125,0,28.937,0H9.646A9.646,9.646,0,0,0,0,9.645V28.937A18.086,18.086,0,0,1,18.085,10.851" transform="translate(0 0)" fill="#19cda0" fill-rule="evenodd" />
                <path d="M34.646,22.146a9.646,9.646,0,1,0,9.646,9.646V12.5a9.646,9.646,0,0,1-9.646,9.646" transform="translate(-5.709 -2.854)" fill="#009669" fill-rule="evenodd" />
                <path d="M35.517,16.117a.6.6,0,0,1,.511.922l-1.481,2.369a7.836,7.836,0,0,1-12.368,9.6l-1.351,2.027a.6.6,0,0,1-1,0l-1.351-2.027a7.836,7.836,0,0,1-12.368-9.6L4.626,17.04a.6.6,0,0,1,.511-.922H8.7a20.487,20.487,0,0,1,23.249,0Z" transform="translate(-1.035 -2.854)" fill="#003c37" fill-rule="evenodd" />
                <path d="M29.723,16.912a18.126,18.126,0,0,0-13.415,0,7.843,7.843,0,0,1,6.707,7.731v.025a7.843,7.843,0,0,1,6.707-7.756Z" transform="translate(-3.724 -3.568)" fill="#ffc300" fill-rule="evenodd" />
            </g>
            <circle cx="5.426" cy="5.426" r="5.426" transform="translate(6.029 15.674)" fill="#fff" />
            <circle cx="3.014" cy="3.014" r="3.014" transform="translate(8.44 18.086)" fill="#003c37" />
            <circle cx="1.206" cy="1.206" r="1.206" transform="translate(10.249 19.894)" fill="#ff5f32" />
            <circle cx="5.426" cy="5.426" r="5.426" transform="translate(21.703 15.674)" fill="#fff" />
            <circle cx="3.014" cy="3.014" r="3.014" transform="translate(24.114 18.086)" fill="#003c37" />
            <circle cx="1.206" cy="1.206" r="1.206" transform="translate(25.923 19.894)" fill="#00b487" />
        </g>
    </g>
</svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>